import { mimicService } from "../../services";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const getWhatsappLogsAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_WHATSAPP_LOGS.REQUEST });
		try {
			const responseData = await mimicService.getWhatsappLogs();
			dispatch({
				type: types.GET_WHATSAPP_LOGS.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_WHATSAPP_LOGS, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
