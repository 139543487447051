import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	subStores: [],
};

const StoreReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_STORES.REQUEST:
		case types.CREATE_STORE.REQUEST:
		case types.UPDATE_STORE.REQUEST:
		case types.UPDATE_STORE_STATUS.REQUEST:
			return { ...state, loading: true };
		case types.GET_STORES.SUCCESS:
			return { ...state, loading: false, subStores: [...payload] };
		case types.CREATE_STORE.SUCCESS:
		case types.UPDATE_STORE.SUCCESS:
		case types.UPDATE_STORE_STATUS.SUCCESS:
			return { ...state, loading: false };
		case types.GET_STORES.FAILURE:
		case types.CREATE_STORE.FAILURE:
		case types.UPDATE_STORE.FAILURE:
		case types.UPDATE_STORE_STATUS.FAILURE:
			return { ...state, loading: false, error: payload };
		default:
			return state;
	}
};

export default StoreReducer;
