import { userService } from "../../services";
import { showToast } from "../../utils";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const getUsersAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_USERS.REQUEST });
		try {
			const responseData = await userService.getUsers();
			dispatch({
				type: types.GET_USERS.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_USERS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const createUserAsync = (userData, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.CREATE_USER.REQUEST });
		try {
			const responseData = await userService.createUser(userData);
			showToast("User Created Successful", "info");
			dispatch({
				type: types.CREATE_USER.SUCCESS,
				payload: { ...responseData },
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.CREATE_USER.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateUserAsync = (userData, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_USER.REQUEST });
		try {
			const responseData = await userService.updateUser(userData);
			showToast("User Updated Successful", "info");
			dispatch({
				type: types.UPDATE_USER.SUCCESS,
				payload: { ...responseData },
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_USER.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateUserStatusAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_USER_STATUS.REQUEST });
		try {
			const responseData = await userService.updateUserStatus(data);
			showToast("User Status Updated Successful", "info");
			dispatch({
				type: types.UPDATE_USER_STATUS.SUCCESS,
				payload: { ...responseData },
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_USER_STATUS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const assignCashiersAsync = (userId, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.ASSIGN_CASHIER.REQUEST });
		try {
			const responseData = await userService.assignCashier(userId);
			dispatch({
				type: types.ASSIGN_CASHIER.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.ASSIGN_CASHIER.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getCashiersAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_CASHIERS.REQUEST });
		try {
			const responseData = await userService.getCashiers();
			dispatch({
				type: types.GET_CASHIERS.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_CASHIERS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getCustomersAsync = (storeId, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_CUSTOMERS.REQUEST });
		try {
			const responseData = await userService.getCustomers(storeId);
			dispatch({
				type: types.GET_CUSTOMERS.SUCCESS,
				payload: responseData.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_CUSTOMERS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getCustomerDetailsAsync = (
	userId,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_CUSTOMER_DETAILS.REQUEST });
		try {
			const responseData = await userService.getCustomerDetails(userId);
			dispatch({
				type: types.GET_CUSTOMER_DETAILS.SUCCESS,
				payload: responseData.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_CUSTOMER_DETAILS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
