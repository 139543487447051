/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from 'react'
import 'animate.css';
import { storageService } from '../../services';
import Sidebar from '../../components/Sidebar';
import TopBar from '../../components/TopBar';
import { useGuard } from '../../hooks/useGuard';
import robot from '../../assets/mimic.svg'
import chat from '../../assets/chat.svg'
import search from '../../assets/search.svg'
import { getWhatsappLogsAsync } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import menuAlt from '../../assets/icons/menu-alt.svg'
import { FaRegUserCircle, FaRobot } from "react-icons/fa";

export default function WhatsAppLogs() {
  useGuard();
  const msgBox = useRef(null);
  const bottomBox = useRef(null);
  const { whatsapp_logs } = useSelector((state) => state.mimic);
  const [minimize, setMinimize] = useState(false)
  const dispatch = useDispatch()
  const [logs, setLogs] = useState([])
  const [groupedMessages, setGroupedMessages] = useState([])
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [selectedPhonenumber, setSelectedPhonenumber] = useState('')
  const [phoneNumberFiltered, setPhoneNumberFiltered] = useState([])
  const [phoneSearch, setPhoneSearch] = useState('')
  const authData = storageService.getAuthData();

  const formattedDateTime = (date) => {
	let logDate = new Date(date);
	// Format the date to "21/05/2024"
	let formattedDate = logDate.toLocaleDateString("en-GB");

	// Format the time to "15:41"
	let formattedTime = logDate.toLocaleTimeString("en-GB", {
		hour: "2-digit",
		minute: "2-digit",
	});
	return `${formattedDate} at ${formattedTime}`;
   };
  const fetchLogs = async () => {
    dispatch(getWhatsappLogsAsync())
  }

  const groupMessages = () => {
    const groupedMessages = logs.reduce((acc, message) => {
      const phoneNumber = message.isBotResponse ? message.to : message.from;
      if (!acc[phoneNumber]) {
        acc[phoneNumber] = [];
      }
      acc[phoneNumber].push(message);
      return acc;
    }, {})

    setGroupedMessages(groupedMessages)
  }

  const getPhoneNumbers = () => {
    return [...new Set(logs
      .filter(message => message.isBotResponse === false)
      .map(message => message.from))];
  }

  const downloadMedia = (url) => {
    // Create a URL object to easily extract the filename (if needed)
    const urlObj = new URL(url);
    const filename = urlObj.pathname.split('/').pop(); // Get the last part of the path (filename)

    // Create a new anchor element to open the URL in a new tab
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'; // Open in a new tab
    link.rel = 'noopener noreferrer'; // Security best practice
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Fetch logs when the component mounts
  useEffect(() => {
    fetchLogs()
  }, [])

  useEffect(()=>{
    setLogs(whatsapp_logs)
  },[whatsapp_logs])

  useEffect(() => {
    groupMessages()
    setPhoneNumbers(getPhoneNumbers())
  }, [logs])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchLogs()
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    let ft = phoneNumbers.filter((phone, index) => {
      if (phone.toString().includes(phoneSearch)) {
        return true
      } else {
        return false
      }
    })
    setPhoneNumberFiltered(ft)

  }, [phoneNumbers, phoneSearch])

  useEffect(() => {
		if (selectedPhonenumber !== "") {
			if (bottomBox.current) {
				bottomBox.current.scrollIntoView({ behavior: 'smooth' });
			}
		}

	}, [selectedPhonenumber])
  return (
    <div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Mimic Logs" />
				<div className="h-full p-[10px]">
					<div className="h-full w-full bg-gray-100 overflow-hidden">
						{/* <h1 className='text-2xl text-grey-800 p-[30px] font-bold shadow-md'>Eric Kayser WhatsApp Logs</h1> */}
						<div className="h-full flex flex-row">
							<div
								className="w-[400px] bg-dark-gray3
                h-full overflow-y-auto p-[20px]"
							>
								<div className="flex flex-row justify-center items-center w-100 bg-white p-[10px] rounded-[5px] mb-[20px]">
									<input
										className="w-full rounded-[5px] focus:outline-none text-sm text-dmsans"
										placeholder="Search phone number"
										onChange={(e) => setPhoneSearch(e.target.value)}
									/>
									<div className="">
										<img src={search} className="w-[25px]" />
									</div>
								</div>

								<div className="text-gray-400 text-sm text-dmsans">
									{phoneNumberFiltered.length} phone numbers
								</div>
								{phoneNumberFiltered.map((phoneNumber, index) => {
									return (
										<div key={index}>
											{typeof groupedMessages[phoneNumber] !== "undefined" && (
												<div
													className={`w-full h-[50px] my-2 font-dmsans
                      text-white text-md text-center px-[15px] py-[13px] cursor-pointer rounded-[5px] bg-dark-gray1 flex justify-between 
                      ${phoneNumber === selectedPhonenumber
															? "bg-orange-600"
															: ""
														}
                      `}
													onClick={() => {
														setSelectedPhonenumber(phoneNumber);
													}}
												>
													<div className="text-[14px] text-white saira-400">{phoneNumber}</div>
													<span
														className={` ${phoneNumber === selectedPhonenumber
															? "bg-orange-800 text-orange-300"
															: "bg-dark-gray3 text-gray-400"
															} 
                        flex h-[22px] mt-[1px] text-xs p-[2px] rounded-[1px] font-bold min-w-[30px] justify-center items-center`}
													>
														{groupedMessages[phoneNumber].length}
													</span>
												</div>
											)}
										</div>
									);
								})}
							</div>
							<div className="bg-dark-gray2 w-full overflow-y-auto pb-[100px]">
								{selectedPhonenumber !== "" && (
									<div>
										<div className="text-dmsans text-[15px] p-[20px] text-gray-300 border-b border-gray-300 bg-opacity-70 backdrop-blur-lg">
											Logs for{" "}
											<span className="text-orange-300">
												{selectedPhonenumber}
											</span>
										</div>
										{typeof groupedMessages[selectedPhonenumber] !==
											"undefined" && (
												<div className="p-[24px]"
													ref={msgBox}
												>
													{groupedMessages[selectedPhonenumber].toReversed().map(
														(this_log, index) => {
															return (
																<div className={`mb-[20px] w-full
																	${index === (this_log.length - 1) ? "last_message" : ""}
																`}
																	id={`${index === (this_log.length - 1) ? "lastmsg" : "others"}`}
																	key={index}>
																	{this_log.isBotResponse === false ? (
																		<div className="mr-auto w-fit flex flex-row gap-2 items-center text-gray-100 saira-400">
																			<FaRegUserCircle
																				size={20}
																				color="#F5F5F5"
																			/>
																			<div className="w-fit justify-end bg-dark-gray1 p-[10px] rounded-[5px] saira-600 text-[14px] text-gray-100">
																				{this_log.body}
																			</div>
																		</div>
																	) : (
																		<div className="text-dmsans ml-auto w-fit flex flex-row items-center align-center text-right gap-2">
																			<div className="w-fit bg-dark-gray1 p-[10px] rounded-[5px] saira-400 text-[14px] text-gray-300 whitespace-pre-line">
																				{this_log.body}
																			</div>
																			<FaRobot size={20} color="#F5F5F5" />
																		</div>
																	)}
																	{this_log.mediaUrl.length > 5 &&
																		<div className={`p-[10px] w-[300px] 
																			bg-dark-gray1 my-[10px] rounded-md 
																			${this_log.isBotResponse
																				? "ml-auto text-right mr-[30px] justify-end"
																				: "ml-[30px]"}
																			`}>
																				{this_log.mediaContentType.toLowerCase().includes('image') &&
																					<img src={this_log.mediaUrl} 
																					className='w-full h-[150px] object-contain'
																					/>
																				}

																				{!(this_log.mediaContentType.toLowerCase().includes('image')) &&
																					<div className='w-full m-y-[10px] p-y-[10px] 
																					font-bold text-dmsans text-[15px]
																					'>PDF Document</div>
																				}
																				<div className='w-full h-[40px] text-orange-500 
																				rounded-full cursor-pointer text-dmsans pt-[10px]'
																				onClick={() => downloadMedia(this_log.mediaUrl)}
																				>Open media</div>
																		</div>
																	}
																	<div
																		className={`flex text-sm p-1 ${this_log.isBotResponse
																			? "ml-auto text-right mr-[30px] justify-end"
																			: "ml-[30px]"
																			}`}
																	>
																		<div className="!text-[10px] saira-600 text-orange-100 text-dmsans">
																			{formattedDateTime(this_log.createdAt)}
																		</div>
																	</div>
																</div>
															);
														}
													)}
													<div ref={bottomBox}
														className="p-3"
													></div>
												</div>
											)}
									</div>
								)}
								{selectedPhonenumber === "" && (
									<div className="p-[20px]">
										<div className="text-[16px] text-light-gray2 text-dmsans">
											Select a Phone number to view logs
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  )
}