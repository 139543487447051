import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	users: [],
	cashiers: [],
	totalCashiers: 0,
	customers: [],
	totalCustomers: 0,
	activeCustomerPerDay: 0,
	activeCustomerToday: 0,
	customerDetails: {},
};

const UserReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_USERS.REQUEST:
		case types.GET_CASHIERS.REQUEST:
		case types.CREATE_USER.REQUEST:
		case types.UPDATE_USER.REQUEST:
		case types.UPDATE_USER_STATUS.REQUEST:
		case types.GET_CUSTOMERS.REQUEST:
		case types.GET_CUSTOMER_DETAILS.REQUEST:
		case types.ASSIGN_CASHIER.REQUEST:
			return { ...state, loading: true };
		case types.GET_USERS.SUCCESS:
			return { ...state, loading: false, users: [...payload] };
		case types.GET_CASHIERS.SUCCESS:
			return {
				...state,
				loading: false,
				cashiers: payload.cashiers,
				totalCashiers: payload.totalCashier,
			};
		case types.GET_CUSTOMERS.SUCCESS:
			return {
				...state,
				loading: false,
				customers: payload.customers,
				totalCustomers: payload.totalCustomers,
				activeCustomerPerDay: payload.activeCustomerPerDay,
				activeCustomerToday: payload.activeCustomerToday,
			};
		case types.GET_CUSTOMER_DETAILS.SUCCESS:
			return { ...state, loading: false, customerDetails: payload };
		case types.CREATE_USER.SUCCESS:
		case types.UPDATE_USER.SUCCESS:
		case types.UPDATE_USER_STATUS.SUCCESS:
		case types.ASSIGN_CASHIER.SUCCESS:
			return { ...state, loading: false };
		case types.GET_USERS.FAILURE:
		case types.GET_CASHIERS.FAILURE:
		case types.CREATE_USER.FAILURE:
		case types.UPDATE_USER.FAILURE:
		case types.UPDATE_USER_STATUS.FAILURE:
		case types.GET_CUSTOMERS.FAILURE:
		case types.GET_CUSTOMER_DETAILS.FAILURE:
		case types.ASSIGN_CASHIER.FAILURE:
			return { ...state, loading: false, error: payload };
		default:
			return state;
	}
};

export default UserReducer;
