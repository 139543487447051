import { storeService } from "../../services";
import { showToast } from "../../utils";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const getStoresAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_STORES.REQUEST });
		try {
			const responseData = await storeService.getAllStores();
			dispatch({
				type: types.GET_STORES.SUCCESS,
				payload: responseData.data.data.subStores,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_STORES.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const createStoreAsync = (storeData, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.CREATE_STORE.REQUEST });
		try {
			const responseData = await storeService.createStore(storeData);
			showToast("Store Created Successful", "info");
			dispatch({
				type: types.CREATE_STORE.SUCCESS,
				payload: { ...responseData },
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.CREATE_STORE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateStoreAsync = (storeData, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_STORE.REQUEST });
		try {
			await storeService.updateStore(storeData);
			showToast("Store Updated Successful", "info");
			dispatch({ type: types.UPDATE_STORE.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_STORE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateStoreStatusAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_STORE_STATUS.REQUEST });
		try {
			const responseData = await storeService.updateStoreStatus(data);
			showToast("Store Status Updated Successful", "info");
			dispatch({
				type: types.UPDATE_STORE_STATUS.SUCCESS,
				payload: { ...responseData },
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_STORE_STATUS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
