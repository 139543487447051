import { identityService, storeService } from "../../services";
import { showToast } from "../../utils";
import * as types from "../types";

export const getAllRolesAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.FETCH_ALL_ROLES.REQUEST });
		try {
			const responseData = await identityService.fetchAllRoles();
			dispatch({
				type: types.FETCH_ALL_ROLES.SUCCESS,
				payload: responseData.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.FETCH_ALL_ROLES.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const loginAsync = (loginData, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.LOGIN.REQUEST });
		try {
			const responseData = await identityService.login(loginData);
			showToast("Login Successful", "info");
			dispatch({ type: types.LOGIN.SUCCESS, payload: { ...responseData } });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.LOGIN.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const ssoLoginAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.SSOLOGIN.REQUEST });
		try {
			const responseData = await identityService.ssoLogin();
			// showToast("Login Successful", "info");
			dispatch({ type: types.SSOLOGIN.SUCCESS, payload: { ...responseData } });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.SSOLOGIN.FAILURE, payload: err });
			errorCallback?.();
			// showErrorMessage(err)
		}
	};
};
export const changePasswordAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.CHANGE_PASSWORD.REQUEST });
		try {
			await identityService.changePassword(data);
			showToast("Password Changed Successfully!", "info");
			dispatch({ type: types.CHANGE_PASSWORD.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.CHANGE_PASSWORD.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const forgotPasswordAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.FORGOT_PASSWORD.REQUEST });
		try {
			await identityService.forgotPassword(data);
			showToast(
				"A Reset Password Link has been Successful sent to your email",
				"info"
			);
			dispatch({ type: types.FORGOT_PASSWORD.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.FORGOT_PASSWORD.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const resetPasswordAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.RESET_PASSWORD.REQUEST });
		try {
			await identityService.resetPassword(data);
			showToast("Password Reset Successfully!", "info");
			dispatch({ type: types.RESET_PASSWORD.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.RESET_PASSWORD.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const showErrorMessage = (err) => {
	const message = parseErrorMessage(err.response.data);
	return showToast(message, "error");
};

function parseErrorMessage(data) {
	if (typeof data?.message === "string" && data?.message !== "") {
		return data.message;
	} else if (data?.message instanceof Array && data.message.length > 0) {
		return data?.message[0];
	} else if (typeof data?.error === "string" && data?.error !== "") {
		return data.error;
	} else if (data?.error instanceof Array) {
		return data?.error[0] || "An error occurred, contact the webmasters";
	} else {
		return "An error occurred, contact the webmasters";
	}
}

export const getCompanyInfoAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.FETCH_COMPANY_INFO.REQUEST });
		try {
			const responseData = await identityService.getCompanyInfo();
			dispatch({
				type: types.FETCH_COMPANY_INFO.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.FETCH_COMPANY_INFO.FAILURE, payload: err });
			errorCallback?.();
			// showErrorMessage(err)
		}
	};
};

export const patchCompanyInfoAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_COMPANY_INFO.REQUEST });
		try {
			await identityService.patchCompanyInfo(data);
			dispatch({ type: types.UPDATE_COMPANY_INFO.SUCCESS });
			showToast("Info Updated Successfully!", "info");
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.UPDATE_COMPANY_INFO.FAILURE, payload: err });
			errorCallback?.();
			// showErrorMessage(err)
		}
	};
};

export const sendSupportMessageAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.SEND_ADMIN_SUPPORT.REQUEST });
		try {
			await storeService.sendSupportMessage(data);
			showToast("Support Message Sent Successfully!", "success");
			dispatch({ type: types.SEND_ADMIN_SUPPORT.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.SEND_ADMIN_SUPPORT.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
