import { transactionService } from "../../services";
import { showToast } from "../../utils";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const getDashboardDataAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_DASHBOARD_DATA.REQUEST });
		try {
			const responseData = await transactionService.getDashboardData(data);
			dispatch({
				type: types.GET_DASHBOARD_DATA.SUCCESS,
				payload: responseData.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_DASHBOARD_DATA.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getAdminDashboardDataAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_DASHBOARD_DATA.REQUEST });
		try {
			const responseData = await transactionService.getAdminDashboardData(data);
			dispatch({
				type: types.GET_DASHBOARD_DATA.SUCCESS,
				payload: responseData.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_DASHBOARD_DATA.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getRevenueDataAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_REVENUE_DATA.REQUEST });
		try {
			const responseData = await transactionService.getRevenueData(data);
			dispatch({
				type: types.GET_REVENUE_DATA.SUCCESS,
				payload: responseData.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_REVENUE_DATA.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getTransactionDataAsync = (id, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_TRANSACTION_DATA.REQUEST });
		try {
			const responseData = await transactionService.getTransactionData(id);
			dispatch({
				type: types.GET_TRANSACTION_DATA.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_TRANSACTION_DATA.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
//Wallet Redux
export const CreateWalletAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.CREATE_WALLET.REQUEST });
		try {
			const responseData = await transactionService.createWallet(data);
			dispatch({
				type: types.CREATE_WALLET.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.CREATE_WALLET.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getBankCodesAsync = (successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_BANK_CODES.REQUEST });
		try {
			const responseData = await transactionService.getBankCodes();
			dispatch({
				type: types.GET_BANK_CODES.SUCCESS,
				payload: responseData.data.data.data.bank,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_BANK_CODES.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getBeneficiaryDetailsAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_BENEFICIARY_DETAILS.REQUEST });
		try {
			const responseData = await transactionService.getBeneficiaryDetails(data);
			dispatch({ type: types.GET_BENEFICIARY_DETAILS.SUCCESS });
			successCallback?.(responseData.data.data);
		} catch (err) {
			dispatch({ type: types.GET_BENEFICIARY_DETAILS.FAILURE, payload: err });
			errorCallback?.();
			// showErrorMessage(err);
		}
	};
};
export const setTriggerWithdrawAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.WITHDRAW_DATA.REQUEST });
		try {
			dispatch({ type: types.WITHDRAW_DATA.SUCCESS, payload: data });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.WITHDRAW_DATA.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const postTriggerWithdrawAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.TRIGGER_WITHDRAW.REQUEST });
		try {
			const responseData = await transactionService.triggerWithdraw(data);
			dispatch({ type: types.TRIGGER_WITHDRAW.SUCCESS });
			successCallback?.();
			showToast(responseData.data.message, "success");
		} catch (err) {
			dispatch({ type: types.TRIGGER_WITHDRAW.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const updateDeliveryStatusAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.UPDATE_DELIVERY_STATUS.REQUEST });
		try {
			const responseData = await transactionService.updateDeliveryStatus(data);
			dispatch({ type: types.UPDATE_DELIVERY_STATUS.SUCCESS });
			successCallback?.(responseData);
			showToast(responseData.data.message, "success");
		} catch (err) {
			dispatch({ type: types.UPDATE_DELIVERY_STATUS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const getTransactionStatusAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_TRANSACTION_STATUS.REQUEST });
		try {
			const responseData = await transactionService.getTransactionStatus(data);
			dispatch({ type: types.GET_TRANSACTION_STATUS.SUCCESS });
			successCallback?.(responseData.data);
		} catch (err) {
			dispatch({ type: types.GET_TRANSACTION_STATUS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const adminUpdateOrderAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.ADMIN_UPDATE_ORDER.REQUEST });
		try {
			const responseData = await transactionService.adminUpdateOrder(data);
			dispatch({ type: types.ADMIN_UPDATE_ORDER.SUCCESS });
			successCallback?.(responseData.data);
		} catch (err) {
			dispatch({ type: types.ADMIN_UPDATE_ORDER.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const adminCompleteOrderAsync = (
	data,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.ADMIN_COMPLETE_ORDER.REQUEST });
		try {
			const responseData = await transactionService.adminCompleteOrder(data);
			dispatch({ type: types.ADMIN_COMPLETE_ORDER.SUCCESS });
			successCallback?.(responseData.data);
		} catch (err) {
			dispatch({ type: types.ADMIN_COMPLETE_ORDER.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
export const adminCancelOrderAsync = (data, successCallback, errorCallback) => {
	return async (dispatch) => {
		dispatch({ type: types.ADMIN_CANCEL_ORDER.REQUEST });
		try {
			const responseData = await transactionService.adminCancelOrder(data);
			dispatch({ type: types.ADMIN_CANCEL_ORDER.SUCCESS });
			successCallback?.(responseData.data);
		} catch (err) {
			dispatch({ type: types.ADMIN_CANCEL_ORDER.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
